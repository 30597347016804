<script>
  import { onMount } from "svelte";
  import { init_i18n, sync_init } from "./i18n/i18n";
  import Background from "./background/Background.svelte";

  import {
    scrollStore,
    projectStore,
    hasScrolledStore,
    headerBackground,
  } from "./store";

  import { Router, Route, link } from "svelte-routing";
  export let url = "";

  import LocaleSwitcher from "./components/LocaleSwitcher.svelte";
  import Index from "./pages/Index.svelte";

  import ProjectWrapper from "./components/ProjectWrapper.svelte";

  import Explore2 from "./pages/explore2/Explore2.svelte";
  import Explore2_en from "./pages/explore2/Explore2_en.svelte";

  import Baratinage from "./pages/baratinage/Baratinage.svelte";
  import Baratinage_en from "./pages/baratinage/Baratinage_en.svelte";

  import Bamit from "./pages/bamit/Bamit.svelte";
  import Bamit_en from "./pages/bamit/Bamit_en.svelte";

  import HydroSoundscapes from "./pages/hydroSoundscapes/HydroSoundscapes.svelte";
  import HydroSoundscapes_en from "./pages/hydroSoundscapes/HydroSoundscapes_en.svelte";

  import Surcote from "./pages/surcote/Surcote.svelte";
  import Surcote_en from "./pages/surcote/Surcote_en.svelte";

  import NotFound from "./pages/NotFound.svelte";

  import Icon from "./components/Icon.svelte";
  import CV from "./pages/index/CV.svelte";

  // window.addEventListener("popstate", () => handleURLchange(null));
  window.history.pushState = new Proxy(history.pushState, {
    apply(target, thisArg, argumentsList) {
      handleURLchange(argumentsList[2]);
      Reflect.apply(target, thisArg, argumentsList);
    },
  });

  $: currentPath = window.location.pathname;
  function handleURLchange(path) {
    // let hash = window.location.toString().match(/(\#[a-z]*)/i);
    // hash = hash && hash[0] ? hash[0] : null;
    // console.log("$locale (handleURLchange)", $locale, path);
    // $locale =
    //   new URL(path).pathname.split("/").filter((c) => c !== "")[0] === "en"
    //     ? "en"
    //     : "fr";
    // console.log("$locale (handleURLchange)", $locale);
    currentPath = path;
    scrollStore.update((s) => ({
      ...s,
      [window.location.pathname]: document.documentElement.scrollTop,
    }));
    if (path) {
      requestAnimationFrame(() => {
        document.documentElement.scrollTo(0, $scrollStore[path] || 0);
      });
    }
  }

  document.addEventListener("scroll", () => {
    $hasScrolledStore = document.documentElement.scrollTop !== 0;
  });

  onMount(async () => {
    await init_i18n();
    let proj_file = await fetch("/projects.json");
    $projectStore = await proj_file.json();
    init = true;
  });
  sync_init();

  let init = false;

  // $: {
  //   $locale =
  //     new URL(window.location.href).pathname
  //       .split("/")
  //       .filter((c) => c !== "")[0] === "en"
  //       ? "en"
  //       : "fr";
  //   console.log("$locale (handleURLchange)", $locale);
  // }

  // $: {
  //   console.log(currentPath);
  // }
</script>

<button
  class={`go-back ${$hasScrolledStore ? "" : "hidden"}`}
  title="Retour en haut de la page"
  on:click={() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // behavior: "instant",
      behavior: "smooth",
    });
  }}
>
  <Icon id="up" />
</button>

<LocaleSwitcher path={currentPath} />

<Background />
<Router {url}>
  <header class:sticky={$hasScrolledStore} class:background={$headerBackground}>
    <a href="/" use:link>
      <svg
        version="1.1"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        ><g
          ><path
            transform="matrix(.72399 .68981 -.67956 .73362 0 0)"
            d="m454.41 34.239a62.432 90.606 0 0 1-62.432 90.606 62.432 90.606 0 0 1-62.432-90.606 62.432 90.606 0 0 1 62.432-90.606 62.432 90.606 0 0 1 62.432 90.606zm623.85-347.58a458.19 485.65 46.356 0 1-40.231 666.76 458.19 485.65 46.356 0 1-667.39-1.4016 458.19 485.65 46.356 0 1 40.231-666.76 458.19 485.65 46.356 0 1 667.39 1.4016z"
            fill-rule="evenodd"
            stop-color="#000000"
            style="paint-order:fill markers stroke"
          /></g
        ></svg
      >

      <h1>ihdev.fr</h1>
    </a>
  </header>
  <div class="fake-header" />
  <!-- {/if} -->

  <main>
    <Route path="/">
      <Index />
    </Route>
    <Route path="/cv">
      <CV />
    </Route>
    <Route path="/en">
      <Index />
    </Route>
    <Route path="/explore2">
      <ProjectWrapper id="explore2">
        <Explore2 />
      </ProjectWrapper>
    </Route>
    <Route path="/en/explore2">
      <ProjectWrapper id="explore2">
        <Explore2_en />
      </ProjectWrapper>
    </Route>
    <Route path="/baratinage">
      <ProjectWrapper id="baratinage">
        <Baratinage />
      </ProjectWrapper>
    </Route>
    <Route path="/en/baratinage">
      <ProjectWrapper id="baratinage">
        <Baratinage_en />
      </ProjectWrapper>
    </Route>
    <Route path="/bamit">
      <ProjectWrapper id="bamit">
        <Bamit />
      </ProjectWrapper>
    </Route>
    <Route path="/en/bamit">
      <ProjectWrapper id="bamit">
        <Bamit_en />
      </ProjectWrapper>
    </Route>
    <Route path="/hydrosounds">
      <ProjectWrapper id="hydrosounds">
        <HydroSoundscapes />
      </ProjectWrapper>
    </Route>
    <Route path="/en/hydrosounds">
      <ProjectWrapper id="hydrosounds">
        <HydroSoundscapes_en />
      </ProjectWrapper>
    </Route>
    <Route path="/surcote">
      <ProjectWrapper id="surcote">
        <Surcote />
      </ProjectWrapper>
    </Route>
    <Route path="/en/surcote">
      <ProjectWrapper id="surcote">
        <Surcote_en />
      </ProjectWrapper>
    </Route>
    <Route path="/*">
      <NotFound />
    </Route>
  </main>
  <footer><p>Copyright &copy; - 2024 - Ivan Horner</p></footer>
</Router>

<style>
  .go-back {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: auto auto 1rem 1rem;
    z-index: 1;
  }
  .go-back.hidden {
    display: none;
  }

  div.fake-header {
    width: 100%;
    height: 4rem;
    max-width: 960px;
  }

  header {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    height: 5rem;
    padding: 1rem;
    /* filter() */
    /* transition: filter */
    animation: 500ms linear 0s normal blurin;
  }
  @keyframes blurin {
    from {
      filter: blur(2rem);
    }
    to {
      filter: blur(0);
    }
  }
  header > a {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  path {
    fill: var(--clr-highlight);
  }
  header > a > svg {
    height: 40px;
  }

  header.sticky > a > svg {
    height: 1.25rem;
  }

  header.sticky.background {
    background-color: rgba(0, 0, 0, 0.8);
  }
  header.sticky {
    height: 2.5rem;
    width: 9rem;
    padding: 0.5rem 1rem;
    z-index: 2;
    transition:
      height 200ms ease,
      padding 200ms ease;
  }
  h1 {
    font-size: 3rem;
    font-weight: 800;
  }
  header.sticky > a > h1 {
    font-size: 1.1rem;
    transition: font-size 200ms ease;
  }
  footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    height: 10rem;
  }
  footer > p {
    font-size: 0.9rem;
  }
</style>
