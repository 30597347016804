<script>
  import { _ } from "svelte-i18n";
  import Icon from "../../components/Icon.svelte";
  function copyEmailAdress() {
    function copy(success = true) {
      copied = true;
      copied_success = success;
      if (set_timeout) clearTimeout(set_timeout);
      set_timeout = setTimeout(() => (copied = false), 5000);
    }
    navigator.clipboard.writeText("contact@ihdev.fr").then(
      function () {
        copy(true);
      },
      function (err) {
        copy(false);
      }
    );
  }
  let copied = false;
  let copied_success = false;
  let set_timeout;

  let subject;
</script>

<div class="container">
  <div class="email">
    <div>
      <span> {$_("contact_mail")} </span>
      <div>
        <span class="highlight">contact@ihdev.fr</span>
        <button on:click={copyEmailAdress}>
          <Icon
            id="copy"
            title="Copier l'adresse e-mail dans le presse-papier"
            size="15"
          />
        </button>
      </div>
    </div>
    <div class={`email-copied ${copied ? "visible" : ""}`}>
      {#if copied_success}
        {$_("contact_copied")}
      {:else}
        {$_("contact_copy_failed")}
      {/if}
    </div>
  </div>
  <div>{$_("contact_form")}</div>
  <form action="https://formsubmit.co/contact@ihdev.fr" method="POST">
    <input type="hidden" name="_subject" bind:value={subject} />
    <!-- <input type="hidden" name="_captcha" value="false"> -->
    <input type="hidden" name="_next" value="https://ihdev.fr/#contact" />
    <input
      type="email"
      name="email"
      placeholder={$_("contact_youremail")}
      required
    />
    <input
      type="text"
      name="subject"
      placeholder={$_("contact_object")}
      bind:value={subject}
      required
    />
    <textarea
      name="content"
      id="content"
      cols="30"
      rows="8"
      placeholder={$_("contact_message")}
      required
    />
    <div class="submit">
      <button class="icon" type="submit" title={$_("send")}>
        <Icon id="paperplane" title={$_("send")} size="15" />
        <span> {$_("send")} </span>
      </button>
    </div>
  </form>
  <div class="additional-links">
    <ul>
      <li>
        <a href="https://github.com/IvanHeriver" target="_blank">GitHub</a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/ivan-horner-a0b634222"
          target="_blank">Linkedin</a
        >
      </li>
      <li>
        <a href="./cv" target="_blank">CV / resume</a>
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  .email > div {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    flex-wrap: wrap;
  }
  .email > div > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .email > div > div > span.highlight {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  .email-copied {
    visibility: hidden;
    font-size: 0.8rem;
    font-weight: 100;
  }
  .email-copied.visible {
    visibility: visible;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }

  input,
  textarea {
    font-family: inherit;
    /* font-size: 1.1rem; */
    padding: 5px;
    margin: 2.5px 0;
    border: none;
    /* border-bottom: 1px dashed var(--clr-highlight); */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
  textarea {
    resize: vertical;
  }
  input::placeholder {
    color: var(--clr-highlight-light);
  }
  textarea::placeholder {
    color: var(--clr-highlight-light);
  }
  input:hover {
    background-color: rgba(5, 5, 5, 0.5);
  }
  textarea:hover {
    background-color: rgba(5, 5, 5, 0.5);
  }

  button {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  .additional-links ul {
    display: flex;
    gap: 1rem;
  }

  .additional-links li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .additional-links a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  .additional-links a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
